var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('custom-alert',{attrs:{"title":"An Error Occurred","message":_vm.errorMessage,"show":_vm.showAlert,"type":"danger"},on:{"dismiss":function($event){_vm.showAlert = false}}}),(!_vm.isEditingMode)?_c('div',{staticClass:"mb-8 md:mb-12 flex justify-between"},[_vm._m(0)]):_vm._e(),(!_vm.isEditingMode)?_c('div',[_c('div',{staticClass:"flex gap-2 mb-12"},[_c('DatePicker',{staticClass:"border rounded-md mb-4",attrs:{"required":true,"type":"date","value":_vm.policyInfo.effectiveDate,"label":_vm.effectiveDateLabel,"fixedLabels":true,"disabled":_vm.disabled,"pickerOptions":{}},on:{"change":function($event){return _vm.$emit('updateChangeRequestData', {
            fieldKey: 'effectiveDate',
            value: $event
          })},"validationChanged":_vm.handleValidationChanged}})],1),_c('div',{staticClass:"flex gap-2 w-full"},[_c('SelectField',{staticClass:"w-4/12",attrs:{"filterable":true,"fixedLabels":true,"disabled":_vm.disabled,"label":_vm.__getText('companies', 'selectCompany'),"selected":_vm.policyInfo ? _vm.policyInfo.companyNum : '',"options":_vm.userCompanies},on:{"change":function($event){_vm.$emit('updateChangeRequestData', {
            fieldKey: 'companyNum',
            value: $event
          });
          _vm.$emit('updateChangeRequestData', {
            fieldKey: 'policyPrefix',
            value: ''
          });},"validationChanged":_vm.handleValidationChanged}}),_c('SelectField',{staticClass:"w-2/12",attrs:{"fixedLabels":true,"label":"Prefix","selected":_vm.policyInfo ? _vm.policyInfo.policyPrefix : '',"options":_vm.companyPrefixOption,"filterable":true,"disabled":_vm.disabled},on:{"change":function($event){return _vm.$emit('updateChangeRequestData', {
            fieldKey: 'policyPrefix',
            value: $event
          })},"validationChanged":_vm.handleValidationChanged}}),_c('TextField',{ref:"field_policyNumber",staticClass:"w-4/12",attrs:{"loading":_vm.loading,"disabled":!_vm.policyInfo.policyPrefix || _vm.disabled,"fixedLabels":true,"required":true,"label":"Policy Number (Omit Leading Zeros)","placeholder":"Policy Number","value":_vm.policyInfo.policyNumber,"validations":[
          {
            method: 'integer',
            error: 'Must be a number'
          }
        ]},on:{"input":function($event){return _vm.$emit('updateChangeRequestData', {
            fieldKey: 'policyNumber',
            value: $event
          })},"blur":function($event){return _vm.doPadPolicyNumber($event)},"validationChanged":_vm.handleValidationChanged},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.preLookUpData.apply(null, arguments)}}}),_c('el-button',{staticClass:"float-right ml-2 h-10",staticStyle:{"font-size":"1rem","margin-top":"-7px"},attrs:{"disabled":_vm.checkIfEmptyFields() || !_vm.formIsValid,"type":"text","icon":"el-icon-search"},on:{"click":_vm.preLookUpData}},[_vm._v(" Lookup Insured Info ")])],1)]):_vm._e(),(_vm.isEditingMode)?_c('div',{staticClass:"border-b pb-4 mb-4"},[_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"font-medium"},[_vm._v("Effective Date")]),_c('div',[_vm._v(_vm._s(_vm.makeHumanReadableDate(_vm.policyInfo.effectiveDate)))])]),_c('div',{staticClass:"flex flex-col sm:flex-row"},[_c('div',{staticClass:"w-full w-4/12"},[_c('div',{staticClass:"font-medium"},[_vm._v("Company Number")]),_c('div',[_vm._v(_vm._s(_vm.policyInfo.companyNum))])]),_c('div',{staticClass:"w-full w-4/12"},[_c('div',{staticClass:"font-medium"},[_vm._v("Policy Number")]),_c('div',[_vm._v(" "+_vm._s(_vm.policyInfo.policyPrefix)+" - "+_vm._s(_vm.policyInfo.policyNumber)+" ")])])])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('h3',{staticClass:"text-lg leading-6 font-medium text-gray-900"},[_vm._v(" Policy Info ")]),_c('p',{staticClass:"mt-1 text-sm text-gray-500"},[_vm._v(" Start by entering the company and policy information and selecting an effective date. ")])])
}]

export { render, staticRenderFns }